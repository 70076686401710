<template>
<ModuleMaster 
:requireSearchField="true"
:requireAddButton="true"
:requirePaginator="true"
:labels="labels"
:numItemsPaginator="totalRecords"
:itemsPerPage="5"
:selectedPage="selectedPage"
:fnCallbackChangePage="handleChangePage"
:fnCallbackCrudAction="handleCrudAction"
>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="true"
        >
            <CrudActions :itemID="row.ID" :callbackCrudAction="handleCrudAction"/>
            <button ref="btnDetails">
                <router-link :to="{ name: 'movementDetails', params: {id: '121DS-A112-XDA', settlementFolio: 'S112WLLAXML'} }">{{ 'Detalles' }}</router-link>
            </button>
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalFormEditMovement" ref="btnForm"></button>
<EditFormMovement :mode="modeEditForm" :title="titleEditFormAction" :fnCallBackPersistenceNotifier="handleItemSaved" :currentItem="currentMovement"/>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import CrudActions from "../../components/c-module-master/crud-actions.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import EditFormMovement from "./movement-edit-form.vue";
import { formatToDatatable } from "../../common/formaterToDatatable"

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        EditFormMovement
    },
    setup() {
        //VARIABLES Y CONSTANTES
        let records = ref([]);
        let modeForm = ref("N");
        let titleEditForm = ref("");
        let currentPage = ref(1);
        const cantRecordsPage = 5;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const btnDetails = ref(null);
        const currentItem = ref(null);

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Movimientos",
            titleNewCrudAction: "Nuevo Movimiento",
        });

        const arrayTitleActions = [
            { action: "N", title: "Nuevo Movimiento" },
            { action: "M", title: "Modificar Movimiento" },
            { action: "V", title: "Visualizar Movimiento" }
        ];

        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "movementType", HeadLabel: "Tipo", Type: "text", VisibleInGrid: true },
            { PropName: "wherehouse", HeadLabel: "Almacén", Type: "text", VisibleInGrid: true },
            { PropName: "user", HeadLabel: "Usuario", Type: "text", VisibleInGrid: true },
            { PropName: "invoiceFolio", HeadLabel: "Factura", Type: "text", VisibleInGrid: true },
            { PropName: "note", HeadLabel: "Concepto", Type: "text", VisibleInGrid: true },
            { PropName: "movementStatus", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },
            { PropName: "whereHouseManagerApproval", HeadLabel: "whereHouseManagerApproval", Type: "text", VisibleInGrid: false },
            { PropName: "shipManagerApproval", HeadLabel: "whereHouseManagerApproval", Type: "text", VisibleInGrid: false },
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        })

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable);
        });

        const currentMovement = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        //FUNCIONES
        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;          
            if(["M", "V", "E"].some(i => i === action)) {
                await getItemById(itemID);
            }

            if(["N", "M", "V"].some(i => i === action) && btnForm.value) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
               btnForm.value.click();
            } else {
                changeStatusItem(itemID, !currentItem.value.isActive);
            }
        }

        const handleItemSaved = async (item) => {
            switch(modeForm.value) {
                case "N":
                    await goToPage(1);
                    break;
                case "M":
                    await updateRowItem(item)
                    break;
                case "E":
                    break;
            }
        }

        const showDetails = () => {
            if(btnDetails.value) {
                btnDetails.value.click();
            }
        }

        const updateRowItem = async (item) => {
            await getItemById(item.Id);
            let itemToUpd = records.value.find(i => i.id == item.Id);
            if(itemToUpd && currentItem.value) {
                itemToUpd.movementType = currentItem.value.movementType;
                itemToUpd.wherehouse = currentItem.value.wherehouse;
                itemToUpd.note = currentItem.value.note;
            }
        }

        const getPagedItems = async() => {
            await ApiService.get(`movements/GetPagedList/currentPage/${currentPage.value}/totalRecords/${cantRecordsPage}`).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            });
        }

        const getItemById = async(id) => {
            await ApiService.get(`movements/${id}`).then(res => {
                currentItem.value = res.data;
                // currentItem.value.invoiceFolio = "NF0001545";
                // currentItem.value.note = "Salida de almacén xxxxx";
            });
        }

        const changeStatusItem = async(id, activate) => {
            debugger
            let url = `movements/${currentItem.value.id}/${ activate ? 'activate' : 'deactivate' }`;
            await ApiService.put(url).then(res => {
                let itemToUpd = records.value.find(i => i.id == id);
                if(itemToUpd) {
                    itemToUpd.isActive = activate;
                }
            });
        }


        return {
            //variables y constantes
            labels,
            btnForm,
            btnDetails,
            totalRecords,
            cantRecordsPage,
            modeEditForm,

            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            selectedPage,
            currentMovement,

            //funciones
            handleChangePage,
            handleCrudAction,
            showDetails,
            handleItemSaved
        }
    },
})
</script>
