<template>
<ModalForm
    id="modalFormEditMovement"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <!-- TIPO DE MOVIMIENTO -->
    <div class="rowDivControl" style="padding-bottom: 20px;">
        <div class="fv-row itemDivControl">
            <label class="required fs-6 fw-bold mb-2">Tipo de Movimiento</label>
            <Field name="MovementTypeId" v-slot="{ value, field }">
                <el-radio-group class="itemControl" v-bind="field" :model-value="value">
                    <el-radio label="c9821fb2-cea6-4b68-92d5-26bd0701c796" border :disabled="readOnly">Entrada</el-radio>
                    <el-radio label="983ace2d-093b-41fe-8379-1ea3bd53ff7f" border :disabled="readOnly">Salida</el-radio>
                </el-radio-group>
            </Field>
        </div>    
    </div>

    <!-- ALMACÉN -->
    <div class="rowDivControl">
        <div class="fv-row itemDivControl">
            <label class="required fs-6 fw-bold mb-2">Almacén</label>
            <Field  name="WherehouseId">
                <SelectOption 
                    class="itemControl"
                    name="WherehouseId"
                    :options="options"
                    :disabled="readOnly"
                />
            </Field>
        </div>
        <div class="fv-row itemDivControl padd">
            <label class="required fs-6 fw-bold mb-2">Folio Factura</label>
            <Field  name="InvoiceFolio" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <input
                        v-bind="field"
                        class="form-control itemControl"
                        type="text" 
                        placeholder="Folio de Factura"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </div>                   
    </div>
        <!-- CONCEPTO -->
    <div class="fv-row rowItemControl">
        <label class="required fs-6 fw-bold mb-2">Concepto</label>
        <Field  name="Note" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <input
                    v-bind="field"
                    class="form-control"
                    type="text" 
                    placeholder="Concepto"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                />
            </el-form-item>
        </Field> 
    </div>
</ModalForm>
</template>

<script>
import { computed, defineComponent, ref, toRefs } from 'vue';
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import { Field } from "vee-validate";
import * as Validations from "yup";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
    components: {
        Field, 
        ModalForm,
        SelectOption
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Movimiento"
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
        currentItem:{
            type: Object,
            required: false,
            default: () => null
        }
    },
    setup(props) {
        const  { mode, currentItem } = toRefs(props);
        const modalForm = ref(null);
        const validationSchema = Validations.object().shape({
            WherehouseId: Validations.string().required().label("WherehouseId"),
            MovementTypeId: Validations.string().default("1").required().label("MovementTypeId"),
            InvoiceFolio: Validations.string().required().label("InvoiceFolio"),
            Note: Validations.string().required().label("Note"),
        });
        const options = ref(
            [
                { key: "de7a25db-7046-4bb5-a97c-7c71ac6e13a8", text: "Almacén Central" },
                { key: "1f5c3c5e-7f64-408e-aabc-e07d6d77af33", text: "Almacén Sucursal" }
            ]
        );

        
        //PROPIEDADES COMPUTADAS
        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const initialValues = computed(() => {
            let movementInitialValues = {};
            if(mode.value === "N") {
                return { MovementTypeId: "c9821fb2-cea6-4b68-92d5-26bd0701c796" }
            } else {
                if(currentItem.value) {
                    movementInitialValues = {
                        MovementTypeId: "c9821fb2-cea6-4b68-92d5-26bd0701c796",
                        WherehouseId: currentItem.value.wherehouseId,
                        InvoiceFolio: currentItem.value.invoiceFolio,
                        Note: currentItem.value.note
                    }
                }
            }
            return movementInitialValues;
        });

        //FUNCIONES
        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                await saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            let url = `movements`;
            item.UserId = "5ef102f0-24ed-443f-9480-788913f2036c";
            await ApiService.post(url, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `movements/${currentItem.value.id}`;
            item.UserId = "5ef102f0-24ed-443f-9480-788913f2036c";
            item.Id = currentItem.value.id;
            await ApiService.put(url, item).then(res => {
                if(res.data.response.statusCode == 201) {
                    modalForm.value.closeModal();
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            });
        }

        return {
            //Variables y constantes
            modalForm,
            options,
            validationSchema,

            //Propiedades computadas
            readOnly,
            initialValues,

            //Funciones
            handleCrudAction
        }
    },
})
</script>

<style lang="scss">

    .el-select {
        width: 100%;
    }

    .itemControl {
        min-width: 250px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }

    .padd {
        padding-left: 10px;
    }

</style>
